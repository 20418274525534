import { css } from '@emotion/react'

import { breakpoint, lightTheme } from '@/theme'

export const BaseStyles = css`
  @font-face {
    font-family: 'Suisse Intl Book';
    src: url(/static/fonts/suisseintl-book-webs.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Suisse Intl Bold';
    src: url(/static/fonts/suisseintl-bold-webs.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Suisse Intl Mono Regular';
    src: url(/static/fonts/SuisseIntlMono-Regular-WebS.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Suisse Intl Mono Bold';
    src: url(/static/fonts/suisseintlmono-bold-webs.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Neue Plak Extended Regular';
    src: url(/static/fonts/neue-plak-extended-regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Neue Plak Extended Bold';
    src: url(/static/fonts/neue-plak-extended-bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  :root {
    width: 100%;
    display: block;
  }
  * {
    box-sizing: border-box;
  }
  html,
  body,
  #__next {
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: ${lightTheme.typography.size.desktop.body3}px;
    font-family: ${lightTheme.typography.font.sim}, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
      helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
    color: ${lightTheme.colors.text};
  }
  @media (max-width: ${breakpoint.max.sm}px) {
    html,
    body,
    #__next {
      font-size: ${lightTheme.typography.size.mobile.body3}px;
    }
  }
  #__next {
    position: relative;
  }
  .loadingContainer {
    background-color: ${lightTheme.colors.beige};
  }
  .spinner {
    width: 72px;
    height: 100%;
    display: flex;
    align-self: center;
    color: ${lightTheme.colors.grey};
    font-size: ${lightTheme.typography.size.desktop.body1}px;
  }
  a,
  a:link,
  a:visited {
    cursor: pointer;
    text-decoration: underline;
    font-size: ${lightTheme.typography.size.desktop.body3}px;
    font-family: ${lightTheme.typography.font.simBold};
    color: ${lightTheme.colors.link};
  }
  a:hover,
  a:active {
    color: ${lightTheme.colors.linkAction};
  }
  a.inverse {
    font-size: ${lightTheme.typography.size.desktop.body2}px;
    font-family: ${lightTheme.typography.font.sim};
    color: ${lightTheme.colors.linkInverse};
    text-decoration: none;
  }
  a.inverse:hover,
  a.inverse:active {
    color: ${lightTheme.colors.linkActionInverse};
  }
  textarea,
  input,
  button,
  select {
    border: none;
    font-family: ${lightTheme.typography.font.sim};
    font-size: ${lightTheme.typography.size.desktop.body3}px;
  }
  // Remove default iOS button styles and override default blue text color
  button {
    margin: 0;
    color: inherit;
    -webkit-appearance: none;
    appearance: none;
  }
  // Ensure styles apply on iOS devices
  @supports (-webkit-touch-callout: none) {
    button {
      color: inherit;
    }
  }
  input {
    padding-left: 1px;
    padding-right: 1px;
  }
  input:focus {
    outline: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${lightTheme.typography.font.npBold};
    font-weight: ${lightTheme.typography.weight.bold};
    line-height: 1;
  }
  h1 {
    font-size: ${lightTheme.typography.size.desktop.heading}px;
  }
  h2 {
    font-size: ${lightTheme.typography.size.desktop.largeTitle}px;
  }
  h3 {
    font-size: ${lightTheme.typography.size.desktop.title1}px;
  }
  h4 {
    font-size: ${lightTheme.typography.size.desktop.title2}px;
  }
  h5 {
    font-size: ${lightTheme.typography.size.desktop.title3}px;
  }
  .no-scroll {
    overflow: hidden !important; // for stopping scroll when drawers and modals are open
  }
`
